var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading || _vm.isLoading,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('events/details', { id: _vm.$route.params.id })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-column flex-wrap"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap px-5 pt-3"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1",staticStyle:{"height":"100%","overflow-y":"scroll","position":"relative"}},[_c('div',{staticClass:"d-flex animate__animated animate__fadeIn flex-column flex-grow-1 flex-wrap flex-shrink-1"},[_c('h3',{staticClass:"ft font-weight-medium px-2 pt-3"},[_vm._v(" Edit Scheduled Events ")]),_c('p',{staticClass:"ft font-weight-medium px-2 pt-1"},[_vm._v(" Schedule notifications and events to be sent to your customers at a later date ")]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 vx-shadow rounded-md"},[_c('ValidationObserver',{staticClass:"vx-shadow white rounded-md ma-2",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.editEvent)}}},[_c('div',[_c('v-toolbar',{staticClass:"ft",attrs:{"dense":"","flat":"","color":"rgb(249, 249, 249)"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","small":"","left":""}},on),[_c('v-icon',{staticClass:"grey--text text--darken-2",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                                          name: 'account.events.list',
                                        })}}},[_vm._v("arrow_back")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Go Back")])]),_c('span'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","small":"","right":"","absolute":""},on:{"click":function($event){return _vm.open('delete')}}},on),[_c('v-icon',{staticClass:"grey--text text--darken-2"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Delete Event")])])],1)],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('v-text-field',{staticClass:"ft ml-10 font-weight-medium black--text",attrs:{"error-messages":errors[0],"autofocus":"","label":"Add Event Title"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('ValidationProvider',[_c('div',{staticClass:"d-flex flex-row flex-full ml-10 mt-n3"},[_c('v-chip-group',{model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},_vm._l((_vm.options),function(text,index){return _c('v-chip',{key:index,staticClass:"box-shadow-light",attrs:{"label":"","small":"","active-class":"primary"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(text))])])}),1)],1)]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft ft-md font-weight-medium ml-10",attrs:{"error-messages":errors[0],"items":_vm.smsIDList,"item-value":"name","item-text":"name","hint":"Provide the sender id here","label":"Sender ID","persistent-hint":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                        var item = ref.item;
return [_c('span',{staticClass:"ft ft-md font-weight-medium"},[_vm._v(_vm._s(item.name))])]}},{key:"no-data",fn:function(){return [_c('v-list-item',{attrs:{"dense":""}},[_c('span',{staticClass:"ft ft-md font-weight-medium text-sm mr-3"},[_vm._v("No Sender ID Available")])])]},proxy:true}],null,true),model:{value:(_vm.senderId),callback:function ($$v) {_vm.senderId=$$v},expression:"senderId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 mt-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                        ? 'error--text'
                                        : 'black--text'},[_vm._v("people")]),_c('v-text-field',_vm._g(_vm._b({staticClass:"ft font-weight-medium mr-1",attrs:{"dense":"","placeholder":"Date","prepend-icon":"","filled":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"ft font-weight-medium",on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-autocomplete',{staticClass:"ft font-weight-medium",attrs:{"dense":"","filled":"","items":_vm.times},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.receivers ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                      ? 'error--text'
                                      : 'black--text'},[_vm._v("people")]),_c('v-combobox',{staticClass:"ft ft-md font-weight-medium",attrs:{"error-messages":errors[0],"items":_vm.selectedOption === 0 ? _vm.groups : _vm.contacts,"item-value":"id","item-text":"name","filled":"","disabled":_vm.receivers,"clearable":"","chips":"","hide-details":"","small-chips":"","single-line":"","multiple":"","dense":"","label":_vm.selectedOption === 0
                                      ? 'Search your favorite groups'
                                      : 'Search your favorite contacts',"persistent-hint":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                      var item = ref.item;
return [_c('span',{staticClass:"ft ft-md font-weight-medium pa-2"},[_vm._v(_vm._s(item.name))])]}},{key:"no-data",fn:function(){return [_c('v-list-item',{attrs:{"dense":""}},[_c('span',{staticClass:"ft ft-md font-weight-medium text-sm mr-3 pa-2"},[_vm._v("Search through your "+_vm._s(_vm.selectedOption === 0 ? 'groups' : 'contacts'))])])]},proxy:true}],null,true),model:{value:(_vm.selectedGroupsOrContacts),callback:function ($$v) {_vm.selectedGroupsOrContacts=$$v},expression:"selectedGroupsOrContacts"}}),(_vm.selectedOption === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","fab":"","small":"","loading":_vm.isLoading,"disabled":_vm.isLoading || _vm.receivers,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('contact/loadMore', {
                                          pages: _vm.paginate.pages,
                                          page: _vm.paginate.page,
                                          limit: 31,
                                        })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("list")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Load more contacts")])]):_vm._e()],1)]}}],null,true)}),_c('v-checkbox',{staticClass:"ml-9 mt-1",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"ft font-weight-medium"},[_vm._v(" Send notification to all my contacts ")])]},proxy:true}],null,true),model:{value:(_vm.receivers),callback:function ($$v) {_vm.receivers=$$v},expression:"receivers"}}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                      ? 'error--text'
                                      : 'black--text'},[_vm._v("subject")]),_c('v-textarea',{staticClass:"ft ft-md font-weight-medium text-sm",attrs:{"filled":"","dense":"","error-messages":errors[0],"label":"Add description","persistent-hint":true},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ft text-sm",attrs:{"depressed":"","type":"submit","disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary"}},[_c('span',{staticClass:"ft-md text-capitalize"},[_vm._v("Save")])])],1)],1)])]}}],null,false,1560607919)})],1)])])])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"animate__animated animate__fadeIn",attrs:{"rounded":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('event/details', { id: _vm.$route.params.id })}}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v("Try Again")])])]},proxy:true}])})],1),_c('div',{staticClass:"messaging-sidebar sidebar--width d-flex flex-column flex-grow-1 mr-3 mb-4 details-sidebar--drawer-bg-none sidebar--container"},[_c('div',{staticClass:"mt-2 d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"vx-shadow mx-2 title dash-side-container animate__animated animate__fadeIn",staticStyle:{"border-radius":"5px"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 ft text-md pa-2 font-weight-medium ml-1"},[_c('i',{staticClass:"material-icons-outlined mt-1 mr-3"},[_vm._v("event")]),_c('span',{staticClass:"ft font-size-sm"},[_vm._v("All Scheduled Events")])])]),(_vm.events.length > 0)?_vm._l((_vm.events),function(event,index){return _c('div',{key:index,staticClass:"animate__animated animate__fadeIn white vx-shadow mx-2 pa-3 mt-1 cursor-pointer notification--card",class:[
                  event.state === 'SCHEDULED' ? 'box-shadow-light1' : '',
                  _vm.$route.params.id === event.id
                    ? 'border-dashed'
                    : 'border-dashed-grey' ],staticStyle:{"border-radius":"8px"},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"open",undefined,$event.key,undefined)){ return null; }_vm.$router
                    .push({
                      name: 'account.events.details',
                      params: { id: event.id },
                    })
                    .catch(function () {})}}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-avatar',{staticClass:"white--text font-weight-bold text-md text-uppercase ft",staticStyle:{"border-radius":"10px"},attrs:{"size":"50","color":"primary"}},[_vm._v(" "+_vm._s(event.name.charAt(0))+" ")]),_c('div')],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 px-3"},[_c('div',{staticClass:"d-flex flex-column flex-fill"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('span',{staticClass:"ft font-weight-medium text-md"},[_vm._v(_vm._s(event.name))])]),(event.state !== 'COMPLETED')?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"indicator-active"})]):_vm._e()])]),_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(_vm._s(_vm._f("truncateContent")(event.payload.message,100)))]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill my-2"},[_c('div',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("event")]),_c('span',{staticClass:"ft font-weight-medium text-uppercase pl-1",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm._f("timestamp")(event.executionTimestamp,'Do MMMM, YYYY hh:mm a')))])])]),([].length > 0)?_c('div',{staticClass:"pt-1"},[[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return _vm._l((6),function(i){return _c('v-avatar',_vm._g({key:i,staticClass:"white--text ml-n2 vx-shadow-dim absolute",staticStyle:{"position":"relative","border":"2px solid white !important"},attrs:{"size":"35","color":"grey"}},on),[_vm._v("A")])})}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s('group.contactId.name')+" - "+_vm._s('group.contactId.phone'))])])]],2):_vm._e()])])])}):_c('EmptyPage',{attrs:{"image":require('@/assets/images/events.svg'),"imageWidth":"100px","title":"No Upcoming Events","subTitle":"All upcoming events will show up here"}})],2)])])])],1),_c('AddSMSSenderID',{attrs:{"state":_vm.dialog('senderId'),"loading":_vm.isLoading},on:{"actions":_vm.close}}),_c('CreateEventDialog',{attrs:{"state":_vm.dialog('details'),"loading":_vm.isLoading},on:{"actions":_vm.close}}),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"message":'Once you delete this event, this operation cannot be undone.',"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }