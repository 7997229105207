





























































































































































































































































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadView, loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment-timezone';
import {
  IAPIKeys,
  IContact,
  IEvents,
  IGroup,
  IPaginate,
  IScheduledEvent,
  ISenderID,
  IUser,
  PayloadState,
} from '@/types/types';
import { Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required, min, max } from 'vee-validate/dist/rules';

const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
const groupModule = namespace('group');
const contactModule = namespace('contact');
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: `Invalid phone number provided`,
});
extend('max', {
  ...max,
  message: `Maximum phone number must be 13`,
});

@Component({
  name: 'Dashboard',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    CreateEventDialog: loadView(`pages/events/dialogs/CreateEventDialog`),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class Dashboard extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;
  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getAllEvents') events!: Array<IScheduledEvent>;
  @eventsModule.Getter('getEventDetails') eventDetails!: IScheduledEvent;
  @groupModule.Getter('getAllGroups') groups!: ReadonlyArray<IGroup>;
  @contactModule.Getter('getAllContacts') contacts!: Array<IContact>;
  @contactModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage' | 'pages'
  > & { limit: number };

  timezones: string[] = [];
  options = ['Groups', 'Contacts'];
  selectedOption = 0;
  date = new Date().toISOString().substring(0, 10);
  menu = false;
  today: string | null = null;
  focus = '';
  type = 'month';
  typeToLabel: { [key: string]: string } = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    '4day': '4 Days',
  };
  selectedEvent = {};
  selectedElement: EventTarget | null = null;
  selectedOpen = false;
  domEvent: Event | null = null;
  newEventElement: EventTarget | null = null;
  trackBy = {
    startDate: '',
    endDate: '',
  };
  times: string[] = [];
  picker = '';
  // event fields
  name = '';
  description = '';
  senderId = '';
  selectedContacts: Array<{ id: number; name: string; phone: string }> = [];
  selectedGroupsOrContacts:
    | Array<number>
    | Array<{ id: number }>
    | Array<IGroup>
    | Array<IContact> = [];
  receivers = false;
  selectedTime = '';
  selectedDate = '';
  eventGroupContactIds: Array<{
    id: number;
    groupId: number | string | null;
    contactId: number | string | null;
  }> = [];

  @Watch('selectedOption')
  onSelectedOptionChange(index: number): void {
    const option = this.eventDetails.selectedOption === 'GROUPS' ? 0 : 1;
    const typedEventDetails = this.eventDetails as IScheduledEvent & {
      receivers: string;
      eventGroupsContacts: Array<{
        id: number;
        uuid: string;
        groupId: IGroup & { groupContactsId: any };
        contactId: IContact;
      }>;
    };
    if (option !== index) {
      this.selectedGroupsOrContacts = [];
      this.eventGroupContactIds = [];
    } else {
      this.eventGroupContactIds = typedEventDetails.eventGroupsContacts.map(
        ({ id, groupId, contactId }) => {
          return {
            id,
            groupId: groupId?.id ?? null,
            contactId: contactId?.id ?? null,
          };
        }
      );
      this.selectedGroupsOrContacts =
        this.eventDetails.selectedOption === 'GROUPS'
          ? typedEventDetails.eventGroupsContacts.map(({ groupId }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { groupContactsId, ...rest } = groupId;
              return {
                ...rest,
              };
            })
          : typedEventDetails.eventGroupsContacts.map(
              ({ contactId }) => contactId
            );
    }
    if (index === 0 && this.groups.length === 0) {
      this.$store.dispatch('group/rawList');
    } else if (this.contacts.length === 0) {
      this.$store.dispatch('contact/list', {
        page: 1,
        limit: 30,
      });
    }
  }

  @Watch('eventDetails')
  onEventDetailsChange(
    event: IScheduledEvent & {
      receivers: string;
      eventGroupsContacts: Array<{
        id: number;
        uuid: string;
        groupId: IGroup & { groupContactsId: any };
        contactId: IContact;
      }>;
    }
  ): void {
    this.name = event.name;
    this.description = event.payload?.message;
    this.date = moment(event.executionTimestamp).format('YYYY-MM-DD');
    this.selectedTime = moment(event.executionTimestamp).format('hh:mm a');
    this.selectedOption = event.selectedOption === 'GROUPS' ? 0 : 1;
    this.eventGroupContactIds = event.eventGroupsContacts.map(
      ({ id, groupId, contactId }) => {
        return {
          id,
          groupId: groupId?.id ?? null,
          contactId: contactId?.id ?? null,
        };
      }
    );
    this.selectedGroupsOrContacts =
      event.selectedOption === 'GROUPS'
        ? event.eventGroupsContacts.map(({ groupId }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { groupContactsId, ...rest } = groupId;
            return {
              ...rest,
            };
          })
        : event.eventGroupsContacts.map(({ contactId }) => contactId);
    this.receivers = event.receivers === 'ALL';
    this.senderId = event.payload?.from;

    if (event.selectedOption === 'GROUPS') {
      this.$store.dispatch('group/rawList');
    } else {
      this.$store.dispatch('contact/list', {
        page: 1,
        limit: 30,
      });
    }
  }

  @Watch('$route.params.id', {
    deep: true,
  })
  onRouterChange(eventId: number): void {
    this.$store.dispatch('events/details', { id: eventId });
  }

  editEvent(): void {
    const groupOrContacts = (this.selectedGroupsOrContacts as Array<{
      id: number;
    }>)?.map((groupOrContact) => (groupOrContact as { id: number })?.id);
    let payload = {
      name: this.name,
      receivers: this.receivers ? 'ALL' : 'SELECTED',
      payload: {
        from: this.senderId,
        to:
          !this.receivers && Number(this.selectedOption) === 1
            ? (this.selectedGroupsOrContacts as Array<IContact>)
                ?.map(
                  (groupOrContact) =>
                    (groupOrContact as { phone: string })?.phone
                )
                ?.join(',')
            : '',
        message: this.description,
        type: 'Quick',
      },
      selectedOption: this.selectedOption === 0 ? 'GROUPS' : 'CONTACTS',
      ...(this.selectedOption === 0
        ? {
            selectedGroups: !this.receivers ? groupOrContacts : [],
          }
        : { selectedContacts: !this.receivers ? groupOrContacts : [] }),
      executionTimestamp: moment(
        `${this.date} ${this.selectedTime}`,
        'YYYY-MM-DD hh:mm a'
      ).format(),
      groups:
        !this.receivers && Number(this.selectedOption) === 0
          ? groupOrContacts
          : [],
      eventGroupContactIds: this.eventGroupContactIds,
    };
    this.$store.dispatch('events/updateEvent', {
      id: this.$route.params.id,
      body: payload,
    });
  }

  deleteEvent(ev: PayloadState): void {
    this.$store.dispatch('events/delete', { ...ev, id: this.$route.params.id });
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.picker = moment().format('YYYY-MM-DD');
    this.$store.dispatch('sms/listSenderID', { isApproved: true });
    this.$store.dispatch('events/list', {
      query: `?activeDate=${moment().format('YYYY-MM-DD')}&isPaginated=false`,
    });
    this.timezones = moment.tz.names();
    console.log(this.timezones);
    this.times = Array(96)
      .fill(0)
      .map((_time, index) =>
        moment()
          .set('hours', Math.floor(index / 4))
          .set('minutes', (15 * index) % 60)
          .format('hh:mm a')
      );
    this.$store.dispatch('checkCreditBalance');
    this.$store.dispatch('events/details', { id: this.$route.params.id });
  }
}
